import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Seo from "@components/seo"

import Thomas from "@images/team/Thomas-Knight-350x350.jpg"
import Preston from "@images/team/Preston-Knight-1-350x350.jpg"
import Kayla from "@images/team/Kayla-Knight-350x350.jpg"
import Matt from "@images/team/Matthew-Cholin-2-350x350.jpg"
import Aaron from "@images/team/Aaron-Luboff-350x350.jpg"
import Bailey from "@images/team/Bailey-350x350.jpg"

const leadership = [
  {
    name: "Thomas Knight",
    position: "CEO & President",
    photo: Thomas,
    url: "/",
  },
  {
    name: "Preston Knight",
    position: "COO - Chief Operations Officer",
    photo: Preston,
    url: "/",
  },
  {
    name: "Kayla Knight",
    position: "CMO - Chief Marketing Officer",
    photo: Kayla,
    url: "/",
  },
]

const teamMembers = [
  {
    name: "Matt",
    position: "Network Administrator",
    photo: Matt,
    url: "/",
  },
  {
    name: "Aaron",
    position: "Help Desk Manager",
    photo: Aaron,
    url: "/",
  },
  {
    name: "Bailey",
    position: "Chief Executive Dog",
    photo: Bailey,
    url: "/",
  },
]

const AboutUsPage = ({ data }) => (
  <Layout>
    <Seo title="About Us" description={data.site.siteMetadata.description} />
    <div className="page-headline">
      <div className="container">
        <h1>About Us</h1>
      </div>
    </div>
    <div id="who-we-are">
      <div className="page-content bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <h2 className="text-center">Who We Are</h2>
              <p>
                IT Specialists started in 1995 as a software development company
                focused on business application software and extended data
                collection interfaces. Our core focus was to develop software
                that expediently collected data from manufacturing systems,
                people and processes resulting in near time productivity and
                payroll reports for management and payroll departments. The ROI
                was management now received accurate production data at the end
                of the shift in comparison to the end of the week.
              </p>
              <p>
                In that software development process, we continually encountered
                the same staff struggling with slow and error laden systems.
                With limited or no support resources available to them, it
                translated to massive inefficiencies and lost productivity.
                Taking advantage of the opportunity to expand our services to
                computer, networks, and support services, we responded with a
                technical service department.
              </p>
              <p>
                Fast forward 20+ years to today and you see a technology world
                that is vastly different than that of the 90’s. Cloud, mobility,
                VOIP, Saas, sensor integration, and a host of unthinkable apps.
                In response to the changing technology, our original Break Fix
                service has evolved into a Managed Services.
              </p>
              <p>
                The one thing you will notice is that our commitment to the Best
                in Class service has not changed, and we continue to pride
                ourselves on maintaining highly professional and rewarding
                relationships. We still maintain some of the original business
                relationships that we started with in the 90’s and we continue
                to welcome the opportunity to service new clients every day.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="our-mission">
      <div className="page-content">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-4 col-lg-3">
              <h2 className="display-4 mb-md-0">Our Mission</h2>
            </div>
            <div className="col-md-8 col-lg-7">
              <p className="lead font-italic">
                To deliver superior technology products and services that our
                customers trust, our employees are proud of, our future depends
                on, and our prospective clients are seeking.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="page-content bg-light pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9">
            <div id="leadership" style={{ paddingTop: `115px` }}>
              <h2 className="text-center">Leadership</h2>
              <div className="row justify-content-center">
                {leadership.map((team, i) => (
                  <div className="col-md-6 col-lg-4 mb-4" key={i}>
                    <div className="team-member h-100">
                      <div className="team-img-round">
                        <div className="team-img-wrapper">
                          <div
                            className="team-img"
                            style={{ backgroundImage: `url(${team.photo})` }}
                          ></div>
                        </div>
                      </div>
                      <h4 className="mb-0 text-secondary">{team.name}</h4>
                      <span
                        className="small text-uppercase text-muted d-block mt-2"
                        style={{ lineHeight: `1.5` }}
                      >
                        {team.position}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div style={{ paddingTop: `115px` }} id="our-team">
              <h2 className="text-center">Our Team</h2>
              <div className="row justify-content-center">
                {teamMembers.map((team, i) => (
                  <div className="col-md-6 col-lg-4 mb-4" key={i}>
                    <div className="team-member h-100">
                      <div className="team-img-round">
                        <div className="team-img-wrapper">
                          <div
                            className="team-img"
                            style={{ backgroundImage: `url(${team.photo})` }}
                          ></div>
                        </div>
                      </div>
                      <h4 className="mb-0 text-secondary">{team.name}</h4>
                      <span
                        className="small text-uppercase text-muted d-block mt-2"
                        style={{ lineHeight: `1.5` }}
                      >
                        {team.position}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutUsPage

export const aboutUsPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
